<template>
  <section>
    <div class="content-header">
      <h2>UN sustainable development goals and career development</h2>
    </div>
    <div class="content-wrapper">
      <p>How can we connect purpose and meaning to work, career planning and setting and achieving career goals?</p>
      <p>Dr. Candy Ho, a researcher and professor formerly from Kwantlen Polytechnic University in British Columbia, now at University of the Fraser Valley, explains the benefits of integrating Sustainable Development Goals (SDGs) into career education and shares her experience with bringing the UN’s SDGs to the classroom in a detailed presentation entitled, Teaching Career Using the UN Sustainable Development Goals. You can access it on the <a href="https://cannexus.ceric.ca/cannexus20-handouts-powerpoints/?limit=5&q=career&catid=23" target="_blank">Cannexus20 Handouts & PowerPoints site</a>.</p>
      <p>You can also read <a href="https://careerguidancesocialjustice.wordpress.com/2021/02/17/the-sdgs-for-career-exploration-and-purpose-interview-with-dr-candy-ho/" target="_blank">this interview</a> with Dr. Ho and <a href="https://www.edcan.ca/articles/how-the-un-sdgs-can-support-career-education/" target="_blank">this article</a> that she’s written on the subject. </p>
      <p class="lm">Learn more</p>
      <p>Many colleges and universities have access to the <a href="https://mysparkpath.com/" target="_blank">SparkPath Challenge Cards</a>, which is another resource that can help students connect purpose and meaning to work. </p>
      <p>The Challenge Cards can help students move from a focus on job titles to the challenges and problems they want to work on, bringing more meaning and adaptability to their career path. </p>
      <p>Contact your Career Services office on campus to see if students have access to this tool.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
